// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+terms+and+conditions#4c99da1d-8287-4a47-83e8-838c208ba27f-450837032
export const TERM_TYPES = {
  LICENSE_AGREEMENT: 'INDIEEULA',
  TERMS_OF_SERVICE: 'SERVICE',
  CHECKOUT_NOTE: 'PAYMENT',
  THIRD_PARTY: 'THIRDPARTY',
  PROTECTION_AUTOMATIC: 'CHILDGAME',
  PARENTAL_CONSENT: 'PARENTUSEAGREE',
  PERSONAL_CONSENT: 'USEAGREE'
};

export const EFFECTIVE_FROM = {
  IMMEDIATE: 'immediate',
  RESERVATION: 'reservation'
};

export const MULTILANGUAGE_DIALOG_NAME = {
  TERM_FORM: 'TERM_FORM',
  EARLY_ACCESS_FORM: 'EARLY_ACCESS_FORM'
};
